import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/store";
Vue.use(VueRouter);

let routes = [
  {
    // will match everything
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    name: "layout",
    redirect: "/landing",
  },
  {
    path: "/home",
    name: "home",
    layout: "dashboard",
    component: () => import("../views/Home.vue"),
  },

  {
    path: "/landing",
    name: "landing",
    component: () => import("../views/Landing.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Register.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../views/Cart.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/viewmatch",
    name: "viewmatch",
    component: () => import("../views/page/ViewMatch.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/upcomingevents",
    name: "upcomingevents",
    component: () => import("../views/page/UpcomingEvents.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/junior-tipsters",
    name: "junior-tipsters",
    component: () => import("../views/tipsters/Junior.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/today-tips",
    name: "today-tips",
    component: () => import("../views/today/Today.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/dashboard/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/soccer-tipsters/:name",
    name: "soccer-tipsters",
    component: () => import("../views/soccer_tipsters/SoccerTipsters.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/fixture-tips/:id",
    name: "fixture-tips",
    component: () => import("../views/soccer_tipsters/Fixture.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = route.layout || parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

// const router = new VueRouter({
//   mode: "hash",
//   base: process.env.BASE_URL,
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     if (to.hash) {
//       return {
//         selector: to.hash,
//         behavior: "smooth",
//       };
//     }
//     return {
//       x: 0,
//       y: 0,
//       behavior: "smooth",
//     };
//   },
// });

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path != from.path) {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  // // some function to check token if request was sent.
  // if (store.getters.getToken) {
  //   if (to.path == "/landing") next("/home");
  //   next();
  // } else if (!store.getters.getToken && to.path != "/landing") {
  //   store
  //     .dispatch("logout")
  //     .then((r) => console.log(r))
  //     .catch((error) => console.log(error));
  //   next("/");
  // } else {
  //   next(); // make sure to always call next()!
  // }
  next();
});

export default router;
