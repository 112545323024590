<template>
  <div>
    <div
      class="container-fluid"
      style="
        background-image: url('img/soccer-login.png');
        background-size: cover;
      "
    >
      <div class="row align-items-center bg-primary px-lg-5">
        <div class="col-12">
          <div class="d-flex justify-content-between">
            <div class="bg-primary text-white text-center py-2">
              {{ timeNow }}
            </div>
            <!-- <div style="width: calc(100% - 100px); padding-left: 90px">
              <VTextMarquee>
                 <p class="mt-2 text-white">
                  Sanctus amet sed amet ipsum lorem. Dolores et erat et elitr
                  sea sed
                </p> 
              </VTextMarquee>
            </div> -->
            <div>
              <select v-model="selectedLanguage">
                <option
                  v-for="lang in languages"
                  :value="lang"
                  :key="lang.short"
                >
                  {{ lang.long }}
                </option>
              </select>
              <img
                class="rounded"
                :src="langImg"
                style="width: 28px; height: 26px; object-fit: cover"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center px-lg-5 p-1">
        <div class="col-12"></div>

        <div class="col-12 text-right">
          <a
            class="btn btn-primary mr-2"
            data-toggle="collapse"
            :href="`#collapse-login`"
            role="button"
            aria-expanded="false"
            :aria-controls="`collapse-login`"
            v-if="$store.getters.getToken === null"
            >{{ $t("LOGIN") }}
          </a>
          <!-- <a class="btn btn-primary mr-2" :href="`/dashboard`">{{
            $t("DASHBOARD")
          }}</a> -->

          <a
            class="btn btn-warning mr-2"
            href="/register"
            v-if="$store.getters.getToken === null"
            >{{ $t("REGISTER") }}</a
          >
          <a
            class="btn btn-success"
            href="/profile"
            v-if="$store.getters.getToken != null"
            >{{ showUsername() }}</a
          >

          <a
            class="btn btn-danger ml-2"
            href="/logout"
            v-if="$store.getters.getToken != null"
            >{{ $t("LOGOUT") }}</a
          >
        </div>
      </div>
      <div class="col-lg-12 collapse" :id="`collapse-login`">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div></div>
          <form>
            <div class="col-sm-12 p-4">
              <div class="row">
                <div class="col-sm-4 form-group text-white">
                  <!-- <label>{{ $t("USERNAME") }}</label> -->
                  <input
                    type="text"
                    :placeholder="$t('USERNAME')"
                    class="form-control rounded"
                    v-model="username"
                  />
                </div>
                <div class="col-sm-4 form-group text-white">
                  <!-- <label>{{ $t("PASSWORD") }}</label> -->
                  <input
                    type="password"
                    :placeholder="$t('PASSWORD')"
                    class="form-control rounded"
                    v-model="password"
                  />
                </div>
                <div class="col-sm-4 form-group text-white">
                  <label> </label>
                  <button
                    type="button"
                    class="btn btn-primary form-control"
                    @click="login"
                  >
                    {{ $t("LETS_LOGIN") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        class="row align-items-center py-2 px-lg-5 bg-light"
        style="background-image: url('img/bg-nav.png'); background-size: cover"
      >
        <div class="col-lg-4">
          <a href="/" class="navbar-brand d-none d-lg-block">
            <h1 class="m-0 display-5 text-uppercase">
              <span class="text-primary">TOP</span>
              <span class="text-white">ZIANBALL</span>
              <!-- <h1>{{ $t("welcome", { name: "TechvBlogs" }) }}</h1> -->
            </h1>
          </a>
        </div>
        <!-- <div class="col-lg-8 text-center text-lg-right">
          <img class="img-fluid" src="img/3319496.png" alt="" />
        </div> -->
      </div>
    </div>

    <!-- Topbar End -->

    <!-- Navbar Start -->
    <div class="container-fluid p-0 bg-light">
      <nav class="navbar navbar-expand-lg navbar-light py-2 py-lg-0 px-lg-5">
        <a href="/" class="navbar-brand d-block d-lg-none">
          <h1 class="m-0 display-5 text-uppercase">
            <span class="text-primary">TOP</span>ZIANBALL
          </h1>
        </a>
        <button
          type="button"
          class="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarCollapse"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- <div
          class="collapse navbar-collapse justify-content-between px-0 px-lg-3"
          id="navbarCollapse"
        >
          <div class="navbar-nav mr-auto py-0">
            <div class="nav-item dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-toggle="dropdown"
                >{{ $t("TODAY_TIPS") }}</a
              >
              <div class="dropdown-menu rounded-0 m-0">
                <a
                  :href="`/${data.link}`"
                  class="dropdown-item"
                  v-for="data in todayTipsDropdown"
                  :key="`key${data.title}`"
                  >{{ $t(data.title) }}</a
                >
              </div>
            </div>
            <div class="nav-item dropdown">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-toggle="dropdown"
                >{{ $t("TIPSTERS") }}</a
              >
              <div class="dropdown-menu rounded-0 m-0">
                <a
                  :href="`/${data.link}`"
                  class="dropdown-item"
                  v-for="data in tipstersDropdown"
                  :key="`key${data.title}`"
                  >{{ $t(data.title) }}</a
                >
              </div>
            </div>
            <a href="category.html" class="nav-item nav-link">{{
              $t("EXCLUSIVETIP_TIPSTERS")
            }}</a>
            <a href="single.html" class="nav-item nav-link">{{
              $t("TODAY_EXCLUSIVETIP_TIPS")
            }}</a>
            <a href="contact.html" class="nav-item nav-link">{{
              $t("BLOG")
            }}</a>
          </div>
          <div>
            <a
              class="btn btn-primary d-inline-flex align-items-center"
              type="button"
              href="/cart"
            >
              {{ $t("CART") }}
              <i class="bi-cart"></i>
            </a>
          </div>
        </div> -->
      </nav>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import Vue from "vue";
import { VTextMarquee } from "vue-text-marquee";
import Login from "../Login.vue";
export default {
  components: { carousel, VTextMarquee, Login },
  computed: {},
  mounted() {
    setInterval(() => {
      this.timeNow = new Date().toLocaleTimeString();
    }, 1000);
    this.langImg = `img/${this.selectedLanguage.long}.png`;
    console.log(this.langImg);
  },
  async created() {
    // console.log('created', this.$store.state.curLanguage);
    Vue.i18n.set(this.$lang.state.curLanguage.short);
  },
  data() {
    return {
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      rememberMe: true,
      selectedLanguage: this.$lang.state.curLanguage,
      languages: this.$lang.state.languages,

      timeNow: new Date().toLocaleTimeString(),
      today: new Date(),
      tipstersDropdown: [
        { title: "JUNIOR_TIPSTERS", link: "junior-tipsters" },
        { title: "SENIOR_TIPSTERS", link: "senior-tipsters" },
        { title: "PREMIUM_TIPSTERS", link: "premium-tipsters" },
        { title: "BUNDLE_TIPSTERS", link: "bundle-tipsters" },
        { title: "TIPSTER_STATIC", link: "tipsters-static" },
      ],
      todayTipsDropdown: [
        { title: "TODAY_TIPS", link: "today-tips" },
        { title: "TODAY_SENIOR_TIPS", link: "today-senior_tips" },
        { title: "TODAY_PREMIUM_TIPS", link: "today-premium_tips" },
        { title: "TODAY_BUNDLE_TIPS", link: "today-bundle_tips" },
        { title: "TODAY_FREE_TIPS", link: "today-free-tips" },
        { title: "PREMIUM_TIPS_RESULT", link: "premium-tips-result" },
      ],
      username: "",
      password: "",
      langImg: "",
    };
  },

  methods: {
    showUsername() {
      return JSON.parse(localStorage.getItem("userData")).username;
    },
    changeLanguage(obj) {
      localStorage.setItem("language", obj.target.value);
    },
    login() {
      console.log(this.username, this.password);
      this.$http
        .post(`${process.env.VUE_APP_BACKEND_API_URL}/auth/login`, {
          username: this.username,
          password: this.password,
        })
        .then(({ data }) => {
          console.log("login", data);
          this.$storex.dispatch("setToken", data.accessToken).then((data) => {
            this.$http
              .get(`${process.env.VUE_APP_BACKEND_API_URL}/auth/me`)
              .then(({ data }) => {
                console.log(data);

                this.$storex.dispatch("setUserData", data).then(() => {
                  this.$fire({
                    text: this.$t("SUCCESS"),
                    type: "success",
                    timer: 3000,
                  }).then((r) => {
                    console.log(this.$store.getters.getUserData);

                    // console.log(r.value);
                  });
                  window.location.reload();
                });
              });

            // this.$routes.push("/landing").then(() => {
            //   window.location.reload();
            //   this.$fire({
            //     text: this.$t("SUCCESS"),
            //     type: "success",
            //     timer: 3000,
            //   }).then((r) => {
            //     console.log(r.value);
            //   });
            // });
          });
        })
        .catch((err) => {
          console.log(err);
          this.$fire({
            // title:this.$t("PASSWORD_DO_NOT_MATCH"),
            text: this.$t(err.message),
            type: "error",
            timer: 3000,
          }).then((r) => {
            console.log(r.value);
          });
        });
    },
  },
  watch: {
    selectedLanguage: function (newLang) {
      Vue.i18n.set(newLang.short);
      this.$lang.commit("setLanguage", newLang.short);
      if (newLang.short === "th") {
        this.langImg = "img/TH.png";
      } else {
        this.langImg = "img/EN.svg";
      }
    },
  },
};
</script>
<style></style>
