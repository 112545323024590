/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from "vue";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import App from "./App.vue";
import DefaultLayout from "./layouts/Default.vue";
import DashboardLayout from "./layouts/Dashboard.vue";
import DashboardRTLLayout from "./layouts/DashboardRTL.vue";

import router from "./router";
import axios from "./axios.js";
import store from "./store/store";
import lang from "./store/lang/lang";

import messages from "./lang";
import VueSimpleAlert from "vue-simple-alert";

// import './plugins/click-away'

import "./scss/style.scss";
import "./lib/easing/easing.min.js";
import "./lib/owlcarousel/owl.carousel.min.js";

Vue.use(Antd);
Vue.use(VueSimpleAlert);

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$storex = store;
Vue.prototype.$lang = lang;
Vue.prototype.$routes = router;
Vue.component("apexchart", VueApexCharts);

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

/* Establish Connection */
// const url =
//   (process.env.VUE_APP_BACKEND_API_URL || "http://localhost:8080") +
//   `?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImQ0NDEyZGFjLTE3OGItNDEzMi1hMmVmLTJhODA4NWI5Y2QwZCIsInVzZXJuYW1lIjoiMDgxMjM2MjQ4MiIsImlhdCI6MTY3NTE2Mjg4MiwiZXhwIjoxNjc1ODU0MDgyfQ.QxOSX9GfGnYoEToO60zYJeSCjYIPUTDb7WchgG0NKkI`;

// const socketConnection = io(url);

// const token = store.getters.getToken && store.getters.getToken.split(" ")[1];
// if (token) {
//   const baseUrl =
//     process.env.VUE_APP_BACKEND_API_URL || "http://localhost:8080";
//   Vue.use(
//     new VueSocketIO({
//       debug: true,
//       connection: io(`${baseUrl}?token=${token}`),
//       vuex: {
//         store,
//         actionPrefix: "SOCKET_",
//         mutationPrefix: "SOCKET_",
//       },
//     })
//   );
// }

// const checkAuthInterval = setInterval(async () => {
//   try {
//     //console.log("checked");
//     await store.dispatch("checkAuth");
//   } catch (e) {}
// }, 5000);

new Vue({
  router,
  store,
  lang,
  render: (h) => h(App),
}).$mount("#app");
