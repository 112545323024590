<!-- 
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
  <div id="app">
    <Navbar />
    <component :is="layout">
      <router-view />
    </component>
    <Footer />
  </div>
</template>

<script>
import Footer from "./views/components/Footer.vue";
import Navbar from "./views/components/Navbar.vue";

export default {
  mounted() {},
  computed: {
    // Sets components name based on current route's specified layout, defaults to
    // <layout-default></layout-default> component.
    layout() {
      return "layout-" + (this.$route.meta.layout || "default").toLowerCase();
    },
  },
  methods: {},
  components: { Navbar, Footer },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
p {
  font-size: 12px;
}
a {
  font-size: 12px;
}
hr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
table {
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
