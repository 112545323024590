import Vue from "vue";
import Vuex from "vuex";
import vuexI18n from "vuex-i18n";

import translationsEn from "../../lang/translations/en.json";
import translationsTh from "../../lang/translations/th.json";

Vue.use(Vuex);

let state = {
  curLanguage: null,
  languages: [
    {
      short: "th",
      long: "TH",
    },
    {
      short: "en",
      long: "EN",
    },
  ],
};

const result = state.languages.find(
  ({ short }) => short === localStorage.getItem("lang")
);

//state.curLanguage = state.languages[0];
if (localStorage.getItem("lang") === null) {
  state.curLanguage = state.languages[0];
}
// state.curLanguage = localStorage.getItem("lang");
else {
  state.curLanguage = result;
}

const mutations = {
  setLanguage(state, lang) {
    state.curLanguage = lang;
    localStorage.setItem("lang", lang);
  },
};

const lang = new Vuex.Store({
  state,
  mutations,
});

Vue.use(vuexI18n.plugin, lang);

Vue.i18n.add("en", translationsEn);
Vue.i18n.add("th", translationsTh);

export default lang;
