<template>
  <div class="col-lg-12 collapse" :id="`collapse-login`">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div></div>
      <form>
        <div class="col-sm-12 p-4">
          <div class="row">
            <div class="col-sm-4 form-group text-white">
              <!-- <label>{{ $t("USERNAME") }}</label> -->
              <input
                type="text"
                :placeholder="$t('USERNAME')"
                class="form-control rounded"
                v-model="username"
              />
            </div>
            <div class="col-sm-4 form-group text-white">
              <!-- <label>{{ $t("PASSWORD") }}</label> -->
              <input
                type="text"
                :placeholder="$t('PASSWORD')"
                class="form-control rounded"
                v-model="password"
              />
            </div>
            <div class="col-sm-4 form-group text-white">
              <label> </label>
              <button
                type="button"
                class="btn btn-primary form-control"
                @click="login"
              >
                {{ $t("LETS_LOGIN") }}
              </button>
            </div>
          </div>
          <!-- <div class="d-flex flex-row-reverse">
                <button type="button" class="btn btn-primary mr-2">
                  {{ $t("LETS_LOGIN") }}
                </button>
              </div> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("logout").then(() => {
      this.$router.push("/").then(() => {
        // window.location.reload();
      });
    });
  },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss">
body {
  background-color: #ffffff;
}
</style>
